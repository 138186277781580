import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import whats from "../../assets/whats.png";
import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header2_4 from "../../assets/header2_4.png";
import header2_5 from "../../assets/header2_5.png";

import CtaWpp from "../../components/CTAWpp";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header2_1,
  },
  {
    id: 2,
    image: header2_2,
  },
  {
    id: 3,
    image: header2_3,
  },
  {
    id: 4,
    image: header2_4,
  },
  {
    id: 5,
    image: header2_5,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            ESPECIALISTA EM ADVOCACIA E CONSULTORIA JURÍDICA
          </h1>

          <p className="text">
            Escritório de Advocacia Especializado na Defesa do Direito do
            Trabalho, Direito Previdenciário, Direito do Consumidor, Direito
            Civil (Família, Contratos, Empresarial) e Direito Penal (Criminal).
          </p>

          <CtaWpp />
        </article>
      </section>

      <a
        href="http://wa.me/+5511973350633?text=Olá,%20Gostaria%20de%20falar%20com%20o%20advogado"
        target="_blank"
        rel="noreferrer"
        id="whats"
      >
        <figure>
          <img src={whats} alt="Ícone do Whatsapp" />
        </figure>
      </a>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">ÁREAS DE ATUAÇÃO</h2>
          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            <div className="mobile">
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </div>
          ) : (
            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={3}
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          <CtaWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
