import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";
import footer1_1 from "../../assets/footer1_1.png";
import footer1_2 from "../../assets/footer1_2.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="left">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <div className="right">
            <h2 className="title">Contatos:</h2>

            <div className="ctas">
              <a
                href="http://wa.me/+5511973350633?text=Olá,%20Gostaria%20de%20falar%20com%20o%20advogado"
                target="_blank"
                rel="noreferrer"
              >
                <figure>
                  <img src={footer1_1} alt="Ícone de Whatsapp" />
                </figure>

                <p className="text">(11) 97335-0633</p>
              </a>

              <a href="mailto:charlles.juridico@gmail.com">
                <figure>
                  <img src={footer1_2} alt="Ícone de Email" />
                </figure>

                <p className="text">charlles.juridico@gmail.com</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
            <b>Charlles Victor Advocacia e Consultoria Jurídica </b>© 2024 -
            Todos os direitos reservados.
          </p>

          <p className="text2">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
