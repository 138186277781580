import React, { useRef } from "react";
import "./styles.css";

import { useNavigate } from "react-router-dom";

const Form = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();

  const scriptURL =
    "https://script.google.com/macros/s/AKfycbxvmVeFBFxuxtMEOoFZEu5_sM2HvRUvlHHZfzprk0J-K0swIbSN-Z9_KBRLb1aC-35ANg/exec";

  const phoneMask = (e) => {
    if (e.target.value.length < 10) {
      return alert("Número inválido");
    } else if (e.target.value.length === 10) {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{4})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    } else {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{5})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    document.getElementById("submit").disabled = true;
    document.getElementById("submit").style.cursor = "default";
    document.getElementById("submit").style.opacity = 0.5;

    fetch(scriptURL, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then(() => {
        document.getElementById("form").reset();
        navigate("/sucesso");
      })
      .catch((err) => console.log(err));
  };

  return (
    <form ref={formRef} name="google-sheet" onSubmit={handleSubmit} id="form">
      <h2 className="title">FALE CONOSCO</h2>
      <h3 className="form-subtitle">
        <b>Conte-nos um pouco sobre o seu caso.</b>
        <br />
        Preencha o formulário, entraremos em contato
      </h3>

      <div className="form-div1">
        <label htmlFor="name">
          Nome: <span>*</span>
        </label>
        <input type="text" id="name" name="Nome" required />
        <label htmlFor="email">
          E-mail: <span>*</span>
        </label>
        <input type="email" id="email" name="Email" required />
        <label htmlFor="tel">
          (DDD) + Telefone: <span>*</span>
        </label>
        <input
          type="tel"
          id="tel"
          name="Telefone"
          onBlur={phoneMask}
          minLength="10"
          maxLength="11"
          required
        />

        <label htmlFor="caso">
          Descreva seu caso: <span>*</span>
        </label>
        <textarea name="Caso" id="caso" cols="30" rows="10"></textarea>
      </div>

      <div className="form-div2">
        <div className="checkbox">
          <input
            type="checkbox"
            id="check"
            name="Contato Comercial"
            value="Quero"
            defaultChecked
          />
          <label htmlFor="check">Aceito receber contato comercial</label> <br />
          <button type="submit" id="submit">
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
