import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import about2 from "../../assets/about2.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <h2 className="title">SOBRE NÓS</h2>

          <p className="text">
            Sabemos que lidar com questões jurídicas pode ser um momento
            delicado e estressante na vida das pessoas, por isso, nos dedicamos
            a oferecer um acolhimento personalizado e empático a cada cliente
            que nos procura. Nosso principal objetivo é garantir que todos se
            sintam ouvidos, compreendidos e amparados em todas as etapas do
            processo jurídico. <br />
            <br />
            Além disso, contamos com uma equipe de profissionais altamente
            qualificados e experientes, capazes de oferecer um trabalho técnico
            de excelência aliado a uma abordagem humanizada, que leva em
            consideração não apenas as questões legais, mas também as
            necessidades emocionais e psicológicas de nossos clientes. Estamos
            aqui para ajudá-lo e defender seus interesses da melhor maneira
            possível. <br /> <br />
            <b> Conte conosco para fazer a diferença em sua vida!</b>
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <h2 className="title">NOSSO DIFERENCIAL</h2>

            <figure className="mobile">
              <img src={about2} alt="Símbolo da Justiça" />
            </figure>

            <p className="text">
              Buscamos desenvolver um trabalho de excelência, com uma abordagem
              humanizada, que leva em consideração não apenas as questões
              legais, mas também as necessidades emocionais e psicológicas de
              nossos clientes. <br />
              <br />
              Estamos sempre disponíveis para esclarecer dúvidas, oferecer
              suporte e auxiliar nossos clientes da melhor forma possível,
              garantindo que cada um deles se sinta acolhido e seguro durante
              todo o processo. Por isso, se você busca por um atendimento
              diferenciado, transparente, ético e comprometido com o bem-estar e
              satisfação do cliente, não hesite em nos contatar.
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={about2} alt="Símbolo da Justiça" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
