import "./styles.css";

import Form from "../../components/Form";

import formPage1 from "../../assets/formPage1.png";
import formPage2 from "../../assets/formPage2.png";

const FormPage = () => {
  return (
    <section id="formPage">
      <article className="container">
        <Form />

        <div className="right">
          <h2 className="title">ONDE ESTAMOS</h2>

          <div className="top">
            <figure>
              <img src={formPage2} alt="Ícone de Localização" />
            </figure>

            <p className="text">
              Avenida Vereador Narciso Yague Guimarães, n. 1145, sala 1105,
              Jardim Armênia, Mogi das Cruzes - SP, CEP: 08780-500
            </p>
          </div>

          <figure>
            <img src={formPage1} alt="Foto do local" />
          </figure>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.435639035169!2d-46.181256624671235!3d-23.516828878830342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cdd830c4b24f5b%3A0xa8866ad61bb24755!2sAv.%20Ver.%20Narciso%20Yague%20Guimar%C3%A3es%2C%201145%20-%20sala%201105%20-%20Socorro%2C%20Mogi%20das%20Cruzes%20-%20SP%2C%2008780-520!5e0!3m2!1spt-BR!2sbr!4v1715518221367!5m2!1spt-BR!2sbr"
            width={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? "400"
                : "100%"
            }
            height={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? "250"
                : "100%"
            }
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </div>
      </article>
    </section>
  );
};

export default FormPage;
